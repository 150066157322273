import Cookies from "js-cookie";
import invariant from "tiny-invariant";
import { parse as UrlParse } from "tldts";


import { KarrotCookie } from "../models/karrot-cookie";

export class KarrotCookieManager {
  public static DOMAIN_SCOPED_COOKIE_FIELD = "_kmpid";
  public static CLICK_THROUGH_COOKIE_FILED = "_kmcid";

  // private expireIn: number = 90 * 24 * 60 * 60 * 1e3;

  private validateField(field: string) {
    invariant(
      [
        KarrotCookieManager.CLICK_THROUGH_COOKIE_FILED, 
        KarrotCookieManager.DOMAIN_SCOPED_COOKIE_FIELD
      ].includes(field),
      "KarrotPixel - Invalid Cookie field",
    );
  }

  private getDomain(): string {
    const parseResult = UrlParse(window.location.hostname);

    invariant(parseResult.domain, "KarrotPixel - Invalid Domain");

    return parseResult.domain;
  }

  public isWritable(): boolean {
    return window.location.protocol.substring(0, "http".length) === "http";
  }

  public get(field: string): KarrotCookie | undefined {
    this.validateField(field);

    const value = Cookies.get(field);

    return value ? KarrotCookie.unpack(value) : undefined;
  }

  public set(field: string, payload: string) {
    this.validateField(field);

    const domain = this.getDomain();
    const now = Date.now();
    const karrotCookie = new KarrotCookie(domain, now, payload);

    Cookies.set(
      field, 
      karrotCookie.pack(), {
        "domain": domain,
        "expires": 90,
        "sameSite": "Lax",
        "path": "/",
      },
    );
  }
}
