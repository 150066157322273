import { KarrotPixel } from "./services";

declare global {
  interface Window {
    karrotPixel?: KarrotPixel;
  }
}

function init() {
  // eslint-disable-next-line dot-notation
  if (window?.["karrotPixel"]) {
    return;
  }

  if (!!window || !!document) {
    // eslint-disable-next-line dot-notation
    window["karrotPixel"] = new KarrotPixel();
  }
}

init();
