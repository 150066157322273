import invariant from "tiny-invariant";

export class KarrotCookie {
  private static prefix = "km";

  constructor(
    private _domain: string,
    private _createdAt: number,
    public readonly payload: string,
  ) { }

  public static unpack(packed: string): KarrotCookie {
    const [prefix, domain, createdAt, payload] = packed.split("|");

    invariant(prefix === this.prefix, "KarrotPixel - Unexpected cookie");
    invariant(domain !== null || domain !== "", "KarrotPixel - Illegal domain");

    const parsedCreatedAt = parseInt(createdAt);
    invariant(!isNaN(parsedCreatedAt), "KarrotPixel - Illegal createdAt");

    invariant(payload !== null || payload !== "", "KarrotPixel - Empty cookie payload");
    
    return new KarrotCookie(
      domain,
      parsedCreatedAt,
      payload,
    );
  }

  public pack(): string {
    return [
      KarrotCookie.prefix,
      this._domain,
      this._createdAt,
      this.payload
    ].join("|");
  }
}
