import type { KarrotPixelEvent, KarrotPixelEventPayload, KarrotPixelMetadata } from "../models";
import type { EventType } from "../type";
import { KarrotRequest } from "./karrot-request-service";
import pixelPackage from "../../package.json";

export class KarrotPixelEventManager {
  constructor(
    private eventCountMap: Map<EventType, number> = new Map(),
    private initialTime: number = Date.now(),
    private karrotRequest: KarrotRequest = new KarrotRequest(),
  ) {}

  private metadata(eventType: EventType): KarrotPixelMetadata {
    return {
      sw: window.screen.width,
      sh: window.screen.height,
      ec: this.eventCountMap.get(eventType) ?? 0,
      it: this.initialTime,
      v: pixelPackage.version,
    };
  }

  public fire(eventType: EventType, payload: KarrotPixelEventPayload, id: string, uid: string, cid?: string) {
    const event: KarrotPixelEvent = {
      id: id,
      uid: uid,
      cid: cid,
      et: eventType,
      rl: window.document.referrer,
      l: window.location.href,
      ts: Date.now(),
      pl: payload,
      md: this.metadata(eventType),
    };

    const navigator = window.navigator;
    if (navigator) {
      this.karrotRequest.sendWithBeacon(event);
    } else {
      this.karrotRequest.sendWithXHR(event);
    }
  }
}
