import { KarrotPixelEvent } from "../models";

export class KarrotRequest {
  private static ENDPOINT: string =
    process.env.NODE_ENV === "alpha"
      ? "https://collect.alpha.kr.karrotmarket.com/p/c"
      : "https://collect.kr.karrotmarket.com/p/c";

  private rebuildPayload(payload: KarrotPixelEvent): Record<string, string> {
    return JSON.parse(
      JSON.stringify({
        ["id"]: payload.id,
        ["uid"]: payload.uid,
        ["cid"]: payload.cid,
        ["et"]: payload.et,
        ["rl"]: payload.rl,
        ["l"]: payload.l,
        ["ts"]: payload.ts.toString(),
        ["pl"]: JSON.stringify({
          ["tag"]: payload.pl.tag,
          ["keyword"]: payload.pl.keyword,
          ["id"]: payload.pl.id,
          ["total_quantity"]: payload.pl.total_quantity,
          ["total_price"]: payload.pl.total_price,
          ["currency"]: payload.pl.currency,
          ["products"]: payload.pl.products?.map((p) => ({
            ["id"]: p.id,
            ["name"]: p.name,
            ["quantity"]: p.quantity,
            ["price"]: p.price,
          })),
        }),
        ["md"]: JSON.stringify({
          ["sw"]: payload.md.sw,
          ["sh"]: payload.md.sh,
          ["ec"]: payload.md.ec,
          ["it"]: payload.md.it,
          ["v"]: payload.md.v,
        }),
      }),
    );
  }

  private makeUrl(payload: KarrotPixelEvent): string {
    const queryString = new URLSearchParams(this.rebuildPayload(payload));

    return KarrotRequest.ENDPOINT + "?" + queryString.toString();
  }

  public sendWithBeacon(payload: KarrotPixelEvent) {
    const url = this.makeUrl(payload);
    navigator.sendBeacon(url);
  }

  public sendWithXHR(payload: KarrotPixelEvent) {
    const url = this.makeUrl(payload);

    const request = new XMLHttpRequest();
    request.open("GET", url);
    request.send();
  }
}
