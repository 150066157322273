import invariant from "tiny-invariant";
import { v4 as UUID } from "uuid";

import type { KarrotPixelEventPayload } from "../models";
import { EventType, EventTypeList } from "../type";

import { KarrotCookieManager } from "./karrot-cookie-manager";
import { KarrotPixelEventManager } from "./karrot-pixel-event-manager";

export class KarrotPixel {
  private karrotEventManager: KarrotPixelEventManager;
  private karrotCookieManager: KarrotCookieManager;
  private trackerId?: string;

  public constructor() {
    // private id: string,
    this.karrotEventManager = new KarrotPixelEventManager();
    this.karrotCookieManager = new KarrotCookieManager();

    this.cookieInit();
  }

  private cookieInit() {
    const clickId = this.getClickIdInQueryParams();

    if (this.karrotCookieManager.isWritable()) {
      const cookieId = this.karrotCookieManager.get(KarrotCookieManager.DOMAIN_SCOPED_COOKIE_FIELD)?.pack();

      if (!cookieId) {
        this.karrotCookieManager.set(KarrotCookieManager.DOMAIN_SCOPED_COOKIE_FIELD, this.getRandomPixelId());
      }
      if (clickId) {
        this.karrotCookieManager.set(KarrotCookieManager.CLICK_THROUGH_COOKIE_FILED, clickId);
      }
    }
  }

  private getClickIdInQueryParams(): string | null {
    const searchParams: URLSearchParams = new URL(window.location.href).searchParams;
    return searchParams.get("kmckid");
  }

  private getRandomPixelId(): string {
    return UUID();
  }

  /**
   * KarrotMarket?? 쿠키에 포함한 비식별화된 식별값
   * 이벤트 종류 및 태그
   * 픽셀이 작동한 웹페이지 URL
   * 픽셀이 작동한 웹페이지로 이동하기 전 URL
   */
  public track(type: EventType, payload: KarrotPixelEventPayload = {}) {
    invariant(this.trackerId, "KarrotPixel - Need to initialize KarrotPixel with TrackerId");
    invariant(EventTypeList.includes(type), "KarrotPixel - Invalid Event Type");

    const clickKarrotCookie = this.karrotCookieManager.get(KarrotCookieManager.CLICK_THROUGH_COOKIE_FILED);
    const pixelKarrotCookie = this.karrotCookieManager.get(KarrotCookieManager.DOMAIN_SCOPED_COOKIE_FIELD)!;

    this.karrotEventManager.fire(type, payload, this.trackerId, pixelKarrotCookie.pack(), clickKarrotCookie?.pack());
  }

  public init(trackerId: string) {
    this.trackerId = trackerId;
  }
}
