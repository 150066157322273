/**
 * 오픈 / 방문
 * 회원가입
 * 로그인
 * 서비스 상세 조회 - 상품조회, 서비스조회 등
 * 상품 장바구니 담기
 * 상품 구매
 * 잠재고객 등록 - 시승 신청, 상담 신청 등
 * 서비스 신청 - 카드발급신청, 계좌개설 신청 등
 */
export const EventTypeList = [
  "ViewPage",
  "CompleteRegistration",
  "Login",
  "ViewContent",
  "AddToCart",
  "Purchase",
  "Lead",
  "SubmitApplication",
  "Install",
] as const;

export type EventType = (typeof EventTypeList)[number];
